/* MAIN HEADERS */
import "typeface-play"
/* SUB HEADERS */
import "typeface-fira-sans"
/* MAIN TEXT */
import "typeface-montserrat"

import { wrapRootElement as wrap } from './root-wrapper';

export const wrapRootElement = wrap;
